import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import 'leaflet/dist/leaflet.css';
import './MapComponent.css'
import MapConfig from '../../../configs/Map.config';
import MapHomeButton from './MapHomeButton';
import MapLegend from './MapLegends';
import MapDropdown from './MapDropdown';
import MapTimeline from './MapTimeline';

const MapComponent = ({ mapData }) => {
    const [showLegend, setShowLegend] = useState(false);
    const [selectedYear, setSelectedYear] = useState(null);

    const toggleLegend = () => {
        setShowLegend(!showLegend);
    };

    const getCenterCordinates = (bounds = false) => {
        if (bounds) {
            return Object.values(mapData[`_${mapData.years[mapData.years.length - 1]}`].points).map((point) => {
                return point.coordinates;
            })
        }
        const center = {
            lng: 0,
            lat: 0   // Horizontal
        }

        Object.values(mapData[`_${mapData.years[mapData.years.length - 1]}`].points).reduce((acc, cur) => {
            acc.lat = acc.lat > cur.coordinates[1] ? cur.coordinates[1] : acc.lat;
            acc.lng = acc.lng < cur.coordinates[0] ? cur.coordinates[0] - 0.01 : acc.lng;
            return acc;
        }, center);

        center.lat = center.lat;
        // return [38.31, -99.83];

        return (center.lat === 0 && center.lng === 0) ? MapConfig.mapCenter : Object.values(center);
    }

    const getMarkerIcon = (icon, color, size, cls) => {
        return new L.DivIcon({
            html: `<i class="fas fa-${icon} ${cls}" 
                style="color:${color}; font-size:${size}px; ${(icon === 'circle') && "border-color:" + MapConfig.ghg.triangleColor + " !important; margin-left: -2px;margin-top: -2px;"}"></i>`,
            className: 'custom-map-pointer',
            iconSize: [size, size],
        });
    }

    const getMapPointer = (position, icon, color, size, fecility, ind, cls) => {
        return <Marker
            // id={`map-pyt-${ind}-${mapData[`_${mapData.years[mapData.years.length-1]}`].points[fecility].GEO_ID}`}
            icon={getMarkerIcon(icon, color, size, cls)}
            position={position}>
            <Popup>
                {fecility}
            </Popup>
        </Marker>
    }

    const getFormatedNumber = (num) => {
        return '<= ' + Number(num.replace('<=', '')).toLocaleString(MapConfig.numberFormat);
    }

    return (
        <MDBRow className='px-2'>
            <MDBCol size={showLegend ? 10 : 12} className='p-0'>
                <MapContainer
                    // ref={mapContainer}
                    center={getCenterCordinates(false)} zoom={MapConfig.zoom}
                    className={`map-container ${!MapConfig.showCredits ? 'hide-credits' : ''}`} 
                    doubleClickZoom={false}>
                    <TileLayer
                        url={MapConfig.tileLayer}
                        attribution={MapConfig.attribution}
                    />
                    <div className='map-north-arrow' style={{
                        backgroundImage: `url(${MapConfig.northArrow})`,
                    }}></div>
                    <MapHomeButton getMapCenter={getCenterCordinates} />
                    <MapLegend toggleLegend={toggleLegend} showLegend={showLegend} getMapCenter={getCenterCordinates} />
                    <MapDropdown options={[{ label: 'Poverty', value: 'poverty' }, { label: 'Minority', value: 'minority' }]} />
                    <MapTimeline years={mapData.years} onSelectYear={setSelectedYear} />

                    {/* Add Map Pointers */}
                    {(selectedYear) && Object.keys(mapData[`_${selectedYear}`].points).map((fecility, ind) => {
                        const latLang = mapData[`_${selectedYear}`].points[fecility].coordinates;
                        return <span>
                            {getMapPointer(
                                latLang,
                                'circle',
                                MapConfig.ghg.circleColor,
                                MapConfig.actions.getSize(mapData[`_${selectedYear}`].points[fecility].GHG_QUANTITY),
                                fecility, ind, 'border border-1 rounded-circle')}
                            {getMapPointer(latLang, 'caret-up', MapConfig.ghg.triangleColor, 25, fecility, ind, 'ms-1')}
                        </span>

                    })}
                </MapContainer>
            </MDBCol>
            <MDBCol size={showLegend ? 2 : 0} className='p-0 ps-2'>
                <div className={`map-legend ${showLegend ? 'show' : 'hide'}`}>
                    <div className="legend-title d-flex justify-content-between px-2">
                        <h5 className='pt-1'>Legend</h5>
                        <button onClick={toggleLegend} className="btn btn-sm btn-outline-dark mt-1  p-2 btn-legend-close" >
                            <MDBIcon fas icon="times" />
                        </button>
                    </div>
                    <hr className='my-1' />
                    <div className="legend-body px-2">
                        <table id="legend1" className='mt-2'>
                            <tbody>
                                <tr>
                                    <td className='text-center'>
                                        <i class="fas fa-caret-up pt-2" style={{ color: MapConfig.ghg.triangleColor, fontSize: '25px' }}></i>
                                    </td>
                                    <td className='text-left ps-3'>Facility Location</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>
                                        <svg height="16" width="36">
                                            <line x1="0" y1="10" x2="36" y2="10" style={{ stroke: '#606060', strokeWidth: 2 }}></line>
                                            <line x1="0" y1="10" x2="36" y2="10" style={{ stroke: '#e6e6e6', strokeDasharray: ' 5, 5', strokeWidth: 1 }}></line>
                                        </svg>
                                    </td>
                                    <td className='text-left ps-3'>Railroad</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>
                                        <svg height="16" width="36">
                                            <line x1="0" y1="10" x2="36" y2="10" style={{ stroke: '#646464', strokeWidth: 2 }}></line>
                                        </svg>
                                    </td>
                                    <td className='text-left ps-3'>Major Roads</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>
                                        <svg height="14" width="34">
                                            <rect x="0" y="0" width="34" height="14" style={{ fill: 'none', stroke: 'rgba(132, 0, 168, 1)', strokeWidth: '4.5', strokeDasharray: '6, 4' }}></rect>
                                        </svg>
                                    </td>
                                    <td className='text-left ps-3'>Comunity Boundary</td>
                                </tr>
                            </tbody>
                        </table>

                        <table id="legend1" className='mt-5'>
                            <tbody>
                                <tr>
                                    <td colspan="2" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Population Percentage Below Poverty Line <br />
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td className='text-center'>
                                        <svg height="14" width="36">
                                            <rect id="legendColor1" height="14" width="36" style={{fill:'rgba(255,255,178,0.5)', strokeWidth:1, stroke:'rgb(0,0,0)'}}></rect>
                                        </svg>
                                    </td>
                                    <td className='text-left ps-3'>1 - 5</td>
                                </tr>
                                <tr><td className='text-center'>
                                    <svg height="14" width="36">
                                        <rect id="legendColor2" height="14" width="36" style={{fill:'rgba(254,204,92,0.5)', strokeWidth:1, stroke:'rgb(0,0,0)'}}></rect>
                                    </svg>
                                </td>
                                    <td className='text-left ps-3'>5 - 10</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>
                                        <svg height="14" width="36">
                                            <rect id="legendColor3" height="14" width="36" style={{fill:'rgba(253,141,60,0.5)', strokeWidth:1, stroke:'rgb(0,0,0)'}}></rect>
                                        </svg>
                                    </td>
                                    <td className='text-left ps-3'>10 - 15</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>
                                        <svg height="14" width="36">
                                            <rect id="legendColor4" height="14" width="36" style={{fill:'rgba(240,59,32,0.5)', strokeWidth:1, stroke:'rgb(0,0,0)'}}></rect>
                                        </svg>
                                    </td>
                                    <td className='text-left ps-3'>15 - 25</td>
                                </tr>
                                <tr>
                                    <td className='text-center'>
                                        <svg height="14" width="36">
                                            <rect id="legendColor5" height="14" width="36" style={{fill:'rgba(189,0,38,0.5)', strokeWidth:1, stroke:'rgb(0,0,0)'}}></rect>
                                        </svg>
                                    </td>
                                    <td className='text-left ps-3'>25 - 100</td>
                                </tr>
                            </tbody>
                        </table>
                        <table id="legend1" className='mt-5'>
                            <tbody>
                                <tr className='pb-2'>
                                    <td colspan="2" >
                                        <div style={{ fontSize: '12px', fontWeight: 'bold', lineHeight: '10px' }}>Greenhouse Gas Emissions</div>
                                        <em style={{ fontSize: '10px' }}>(Greenhouse Gas Emissions)</em>
                                        <br /><br />
                                    </td>
                                </tr>
                                {Object.keys(MapConfig.sizes).map((size, ind) => (<tr>
                                    <td className='text-center'>
                                        <i class="fas fa-circle" style={{ 
                                            color: MapConfig.ghg.circleColor, 
                                            fontSize: MapConfig.sizes[size],
                                            border: `1px solid ${MapConfig.ghg.triangleColor}`,
                                            borderRadius: '50%', 
                                            }}></i>
                                    </td>
                                    <td className='text-left ps-3'>{getFormatedNumber(size)}</td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </MDBCol>
        </MDBRow>
    )
}

export default MapComponent