import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectToken, selectTokenTimeStamp } from '../../../redux/authSlice';
import AppLayout from '../../../layouts/app/appLayout/AppLayout';
import { Navigate, useNavigate } from 'react-router-dom';
import { selectLastEventTime } from '../../../redux/userSlice';
import appConfig from '../../../configs/App.config';

const Wrapper = () => {
    const token$ = useSelector(selectToken);
    // const oldTime = useSelector(selectTokenTimeStamp);
    const newTime = useSelector(selectLastEventTime);
    const navgate = useNavigate();
    const selector = useSelector;
    const [ticks, setTicks] = useState(0);
    useEffect(() => {
        if (token$) {
            setTimeout(() => {
                const diff = getTimeDiff(); //newTime - oldTime;
                // dif > 10 min
                const checkTime = 1000 * 60 * appConfig.idelTimeOut;
                if (diff >= checkTime) {
                    navgate("/app/logout");
                }else{
                    setTicks(ticks+1);
                }
            }, 60*1000);
        }
    }, [token$, ticks]);

    const getTimeDiff = () => {
        const curTime = new Date().getTime();
        return Math.abs(curTime - newTime);
    }

    return (
        <>
            {token$ ? <AppLayout /> : <Navigate to="/" />}
        </>
    )
}

export default Wrapper
