import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { MDBIcon } from 'mdb-react-ui-kit';
import MapConfig from '../../../configs/Map.config';

// Custom Legend Component
const MapLegend = ({ toggleLegend, showLegend, getMapCenter }) => {
    // const map = useMap();

    // useEffect(() => {
    //     console.log('showLegend', showLegend);
    //     const center = getMapCenter();
    //     if(showLegend){
    //         // center[0] = center[0] - 1;
    //         // center[1] = center[1] - 50;
    //         // MapConfig.zoom = 5;
    //     }
    //     center[0] = center[0] - 1;
    //     map.setView(center, MapConfig.zoom);
    // }, [showLegend]);
    return (
        <button
            onClick={toggleLegend}
            className="map-legend-button"
            title="Show/Hide Legend"
        >
            <MDBIcon fas icon="list-ul" className="text-dark" />
        </button>
    );
};

export default MapLegend;
