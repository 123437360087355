const MapDropdown = ({options, onChange}) => {
    return (
        <div className="map-type-dropdown">
            <label>Select Map:</label>
            <select
                className="form-select"
                onChange={onChange}
            >
                {options.map((option, index) => (
                    <option key={index} selected={index===0} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default MapDropdown;
