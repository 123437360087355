import { useMap } from "react-leaflet";
import MapConfig from "../../../configs/Map.config";
import { MDBIcon } from "mdb-react-ui-kit";

const MapHomeButton = ({getMapCenter}) => {
    const map = useMap(); 

    const resetView = () => {
        map.setView(getMapCenter(), MapConfig.zoom);
    };

    return (
        <button
            onClick={resetView}
            className="map-home-button"
            title="Reset to Home"
        >
            <MDBIcon fas icon="home" className="text-dark" />
        </button>
    );
};

export default MapHomeButton;