import { useEffect, useState } from 'react';
import { MDBBtn, MDBIcon, MDBRange } from 'mdb-react-ui-kit';
import MapConfig from '../../../configs/Map.config';

// Custom Legend Component
const MapTimeline = ({ years, onSelectYear }) => {
    const [selectedYear, setSelectedYear] = useState(0);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        if (years.length > 0) {
            setSelectedYear(years[years.length - 1]);
        }
    }, [years]);

    useEffect(() => {
        onSelectYear(selectedYear);
    }, [selectedYear]);

    const forwardHandle = () => {
        let index = years.indexOf(selectedYear);
        if (index === years.length - 1) {
            setSelectedYear(years[0]);
        }else{
            setSelectedYear(years[index + 1]);
        }
    }

    const backwardHandle = () => {
        let index = years.indexOf(selectedYear);
        if (index === 0) {
            setSelectedYear(years[years.length - 1]);
        }else{
            setSelectedYear(years[index - 1]);
        }
    }

    const play = (canPuse=false) => {
        if(canPuse){
            clearInterval(timer);
            setTimer(null);
            return;
        }
        let index = years.indexOf(selectedYear);
        let interval = setInterval(() => {
            setSelectedYear(years[index]);
            if (index === years.length - 1) {
                index = 0;
            } else {
                index++;
            }
        }, 1000);
        setTimer(interval);
    }

    return (
        <div className="map-timeline">
            <div className="d-flex justify-content-between map-timeline-title">
                <h6>Selected Year: {selectedYear}</h6>
                <div className="map-timeline-buttons">
                    {/* play, forward and backward buttons */}
                    <MDBBtn color="light" size="sm" className='gradient' onClick={backwardHandle}>
                        <MDBIcon fas icon="fast-backward" />
                    </MDBBtn>
                    <MDBBtn color="light" size="sm" className='gradient ms-2' onClick={(e)=>{play(timer? true:false)}}>
                        <MDBIcon fas icon={timer? 'pause' : 'play'} />
                    </MDBBtn>
                    <MDBBtn color="light" size="sm" className='gradient ms-2' onClick={forwardHandle}>
                        <MDBIcon fas icon="fast-forward" />
                    </MDBBtn>
                </div>
            </div>

            <div className="map-timeline-slider">
                <MDBRange
                    min={years[0]}
                    max={years[years.length - 1]}
                    value={selectedYear}
                    step={1}
                    onChange={(e) => {
                        setSelectedYear(e.target.value);
                    }}
                    disableTooltip={true}
                />
            </div>
            <div className="d-flex justify-content-between map-slider-handle-block">
                {years.map((year, index) => (
                    <span key={'map-slider-handle'+index} className='map-slider-handle'
                    onClick={()=>{
                        setSelectedYear(year);
                    }}>
                        <span>|</span>
                        {year}
                    </span>
                ))}
            </div>

        </div>
    );
};

export default MapTimeline;
