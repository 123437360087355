import { CSSProperties, useEffect, useRef, useState } from 'react'
import StickyMenu from '../../../layouts/app/stickyNav/StickyMenu';
import {
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import Highcharts, { objectEach } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PlaceholderLoading from 'react-placeholder-loading'
import html2canvas from "html2canvas";
import L from "leaflet";
import './Dashboard.css'
import { useDispatch, useSelector } from 'react-redux';
import {
    selectZipCode,
    setZipCode,
    setBredcrumbs,
    setIsZipSearching,
    selectIsZipSearching,
    setIsLeftnavUpdated,
    selectSelectedCBO,
    selectChemicalName
} from '../../../redux/appSlice';
import appConfig from '../../../configs/App.config';
import CommonServoces from '../../../services/commonServices';
import { selectUserEmail, selectTokenData, selectToken } from '../../../redux/authSlice';
import { useLocation, useParams } from 'react-router-dom';
// import { withAITracking } from '@microsoft/applicationinsights-react-js';
// import { reactPlugin, appInsights } from '../../../services/appInsights';
import Grid from '../../common/grid/Grid';
import DataCacher from '../../../services/dataCacher';
import ChartMaker from '../../../services/chartMaker';
import chartsConfig from '../../../configs/charts.config';
import RenderGauge from '../../common/gaugeComponent/RenderGauge';
import ChemicalDropdown from '../../common/chemDropdown/ChemicalDropdown';
import CBOSearch from '../../../layouts/app/cboSearch/CBOSearch';

import GhgMapTxt from "../dashboardTxt/GhgMapTxt";
import TimelineItem from 'antd/es/timeline/TimelineItem';
import TimelineTxt from '../dashboardTxt/TimelineTxt';
import PieTxt from '../dashboardTxt/PieTxt';
import LineTxt from '../dashboardTxt/LineTxt';
import TableTxt from '../dashboardTxt/TableTxt';

import TriMapTxt from "../dashboardTxt/TriMapTxt";
import TriPieTxt from '../dashboardTxt/TriPieTxt';
import TriTableTxt from '../dashboardTxt/TriTableTxt';
import TriLineTxt from '../dashboardTxt/TriLineTxt';

import CejstMapTxt from '../dashboardTxt/CejstMapTxt';
import SviMapTxt from '../dashboardTxt/SviMapTxt';
import EbmMapTxt from '../dashboardTxt/EbmMapTxt';
import SvmMapTxt from '../dashboardTxt/SvmMapTxt';
import HvmMapTxt from '../dashboardTxt/HvmMapTxt';

import CopyrightsFooter from '../CopyrightsFooter/CopyrightsFooter';

import CircularProgress from '../../common/progress/Circular'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import GeneratePDF from '../pdf/GeneratePDF';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import domtoimage from 'dom-to-image';
import 'leaflet/dist/leaflet.css';
import MapComponent from '../../common/mapComponent/MapComponent';


const Dashboard = () => {
    const userData$ = useSelector(selectTokenData);
    const selectedCBO$ = useSelector(selectSelectedCBO);
    const [userData, setUserData] = useState(userData$);
    const [activeTab, setActiveTab] = useState(() => {
        return appConfig.dashboardTabs.find((tab) => tab.isActive).key;
    });
    const [chartOptions, setChartOptions] = useState({});
    const [trendChartOptions, setTrendChartOptions] = useState({});
    const [isLoadingTrends, setIsLoadingTrends] = useState(false);
    const [gridData, setGridData] = useState({
        headers: [],
        data: [],
        features: [],
        graphValues: {}
    })
    const mapRef = useRef(null)
    const trendRef = useRef(null)
    const gridRef = useRef(null)
    const trendChartRef = useRef(null)
    const [saveRptModal, setSaveRptModal] = useState(false);
    const [reportName, setReportName] = useState('');
    const [isRptSaving, setIsRptSaving] = useState(false);
    const [hasData, setHasData] = useState(true);
    const [isNodatTosted, setIsNodatTosted] = useState(false);
    const [canShowExportPDF, setCanShowExportPDF] = useState(false);
    const [pdfFormData, setPdfFormData] = useState({});

    const dispatch = useDispatch();
    const zipCode$ = useSelector(selectZipCode);
    const token$ = useSelector(selectToken);
    const isZipSearching$ = useSelector(selectIsZipSearching);
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const chemicalName$ = useSelector(selectChemicalName);

    const [basicModal, setBasicModal] = useState(false);
    const toggleOpen = () => setBasicModal(!basicModal);

    const [isLoading, setIsLoading] = useState(true);

    const mapPageRef = useRef();
    const pieChartPageRef = useRef();
    const trendsGridRef = useRef();
    const docInstance = useRef();
    const mapRefNew = useRef(null);

    const [mapImg, setMapImg] = useState('');
    const [piechartImg, setPiechartImg] = useState('');
    const [trendsChartImg, setTrendsChartImg] = useState('');
    const [mapData, setMapData] = useState({});

    setTimeout(() => {
        setIsLoading(false);
    }, 1000);

    useEffect(() => {
        if (!zipCode$) {
            if (!selectedCBO$) {
                dispatch(setZipCode(userData.USER_TYPE === "CBO" ? userData.COMUNITY_ID : zipCode$));
            } else {
                let msg = `Invalid ${userData.USER_TYPE === "CBO" ? 'Comunity ID' : 'Zipcode'}.`
                toast.error(msg, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            }
        } else {
            dispatch(setBredcrumbs([
                { name: 'Dashboard', path: '/dashboard' }
            ]));
            if (!selectedCBO$) {
                setUserData(userData$);
            } else {
                setUserData(selectedCBO$);
            }

            if (activeTab !== 'CHEM') {
                getGridData(zipCode$, activeTab);
            }
        }
    }, [zipCode$, activeTab, selectedCBO$]);


    useEffect(() => {
        if (userData.USER_TYPE === "RESIDENT") {
            CommonServoces.getLocationData(zipCode$)
                .then((data) => {
                    setCity(data.city);
                    setState(data.state);
                    setCountry(data.country);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [zipCode$]);

    useEffect(() => {
        setIsLoadingTrends(false);
    }, [trendChartOptions]);

    useEffect(() => {
        setIsNodatTosted(false);
        setHasData(true);
    }, [selectedCBO$]);

    useEffect(() => {
        // if (!isNodatTosted) {
        if (!hasData) {
            setIsNodatTosted(true);
            // let msg = "No data available for the {SPEL}."
            let msg = "No Facilities Found For The {SPEL}."
            if (userData.USER_TYPE === "CBO") {
                msg = msg.replace("{SPEL}", `Community - ${selectedCBO$ ? selectedCBO$.FULLNAME : userData.FULLNAME}`);
            } else {
                msg = msg.replace("{SPEL}", `ZIP Code - ${zipCode$}`);
            }

            toast.error(msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            setTimeout(() => {
                setIsNodatTosted(false);
                // setHasData(true);
            }, 3000);

        }
        // }
    }, [hasData]);

    const getGridData = (zipCode, tab) => {
        setIsLoadingTrends(true);
        setTrendChartOptions({});
        setChartOptions({});
        setTrendChartOptions({});
        setIsZipSearching(true)
        dispatch(setIsZipSearching(true));
        // CommonServoces.getTrendsData(userData.extension_isCBO ? userData.extension_comunityId : zipCode, tab.toLowerCase(), userData.extension_isCBO ? 'cbo' : 'res')
        // CommonServoces.getTrendsData(userData.USER_TYPE === "CBO" ? userData.COMUNITY_ID : zipCode, tab.toLowerCase(), userData.USER_TYPE === "CBO" ? 'cbo' : 'res')
        CommonServoces.getTrendsData(zipCode, tab.toLowerCase(), userData.USER_TYPE === "CBO" ? 'cbo' : 'res')
            .then((data) => {
                if (data) {
                    if (data.error) {
                        toast.error(`Data Error: ${data.error.code}: ${data.error.message}`, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"
                        });
                        dispatch(setIsZipSearching(false));
                        return;
                    }
                    data.zipCode = zipCode;

                    setMapData(data.mapPoints);


                    // DataCacher.set(tab, data);
                    // setGridData(DataCacher.get(tab));
                    if (tab === 'TRI' || tab === 'GHG') {
                        console.log('data', data);
                        const trcData = ChartMaker.make(tab, data);
                        const piData = ChartMaker.makePieChart(data, tab, (tab === 'GHG' ? 'chemical' : null));

                        if (data.data.length === 0) {
                            setHasData(false);
                        } else {
                            setHasData(true);
                        }

                        setTimeout(() => {
                            setGridData(data);
                            setChartOptions(piData);
                            setTrendChartOptions(trcData);
                            setIsLoadingTrends(false);
                            setIsZipSearching(false)
                            dispatch(setIsZipSearching(false));
                        }, 1000);
                    }
                }
            });
        // }
    }

    const executeScroll = (elmRef) => elmRef.current.scrollIntoView()
    const toggleShow = () => {
        setSaveRptModal(!saveRptModal);
        setReportName(`ZIP Code - ${zipCode$}`);
    };
    const onChangeRptName = (e) => {
        setReportName(e.target.value);
    }
    const onSaveRpt = () => {
        setIsRptSaving(true);
        const payLoad = {
            "USER_ID": userData.ID,
            "NAME": reportName,
            "CODE": zipCode$.toString(),
        };

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token$}`);

        var raw = JSON.stringify(payLoad);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/reports/save`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode >= 400) {
                    let errMsg = result.errMessage || 'Unable to save the report.';
                    toast.error(errMsg, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setIsRptSaving(false);
                    return;
                }

                setIsRptSaving(false);
                setSaveRptModal(false);
                dispatch(setIsLeftnavUpdated(true));
                toast.success('Report saved successfully.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch(error => console.log('error', error));
    }

    const getCurrentYear = () => {
        return new Date().getFullYear();
    }

    const clearSearch = () => {
        dispatch(setZipCode(userData.ZIPCODE));
    }

    const showPDFOptions = () => {
        setPdfFormData((state) => {
            return {
                ...state,
                FILE_NAME: `${getCurrentYear()}-${activeTab}-${zipCode$}-${userData.FULLNAME}Report`,
                SHOW_MAP: true,
                MAP_YEAR: getCurrentYear(),
                SHOW_PIE_CHART: true,
                SHOW_BAR_CHART: true,
                SHOW_TABLE: true,
            }
        });

        html2canvas(mapRefNew.current).then(async (canvas) => {
            // const imgData = canvas.toDataURL('image/png');
            const imgData = await domtoimage.toPng(mapRefNew.current);
            setMapImg(imgData); // Pass the image data up to the parent
          });

        html2canvas(pieChartPageRef.current.container.current).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            setPiechartImg(imgData); // Pass the image data up to the parent
        })

        html2canvas(trendChartRef.current.container.current).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            setTrendsChartImg(imgData); // Pass the image data up to the parent
        })

        setCanShowExportPDF(true);
    }

    return (
        <>
            {(isLoading) ? (
                <div className="loading">
                    <CircularProgress />
                </div>
            ) : (
                <>

                    {/* Tabs */}
                    <div className='d-flex mb-3 px-1 py-1 bg-white  shadow-1 fix-width2 tab-let'>
                        <div className='d-flex flex-grow-1 justify-content-start ms-2'>
                            {
                                appConfig.dashboardTabs.map((tab, index) => {
                                    return (
                                        <label key={index} className={`tablet-tab tablet-tab-${tab.key.toLowerCase()} ${activeTab === tab.key ? 'active' : ''} px-3 py-2 mx-1 d-flex flex-row`} htmlFor={tab.key}>
                                            <input type="radio" name="tab" id={tab.key} autoComplete="off" className='visually-hidden' checked={activeTab === tab.key} onChange={() => { }} onSelect={() => { setActiveTab(tab.key); }} onClick={() => { setActiveTab(tab.key); }} />
                                            <span className="material-symbols-outlined me-2">{tab.icon}</span>
                                            <span className='tab-label'>{tab.title}</span>
                                        </label>
                                    )
                                })
                            }
                        </div>
                        {userData.USER_TYPE === "CBO" &&
                            <div className='flex-grow-1 d-flex justify-content-end'>
                                <CBOSearch />
                            </div>
                        }
                    </div>

                    {/* save report */}
                    <MDBCard className='mb-3 mt-4 border-primery'>
                        <MDBCardBody className='p-0 px-3'>
                            {/* <MDBCardText> */}
                            {/* {isZipSearching$ ? <PlaceholderLoading className="loader" shape='rect' width={'100%'} height={30} />
                        :  */}
                            <div className='d-flex flex-sm-row'>
                                {appConfig.lables[activeTab.toLowerCase()].mapTitle ?
                                    <div className="flex-grow-1 text-lg">
                                        <h5 className="card-title p-2 m-0" style={activeTab === 'CHEM' ? { paddingTop: '5px' } : null}>
                                            {/* {activeTab} Emissions from Large Facilities {gridData.headers.length > 1 ? `${gridData?.headers[1]} - ${gridData?.headers[gridData?.headers?.length - 1]}` : '--'} */}
                                            {userData.USER_TYPE === "CBO" ?
                                                (appConfig.lables[activeTab.toLowerCase()].mapTitle.replace("at {zipcode}", `for ${userData.FULLNAME}`).replace("{zipcode}", `${userData.FULLNAME}`)) :
                                                (appConfig.lables[activeTab.toLowerCase()].mapTitle.replace("{zipcode}", zipCode$)).replace("{year}", getCurrentYear())}
                                            {(userData.USER_TYPE !== "CBO" && zipCode$ != userData.ZIPCODE) &&
                                                <MDBBtn className='mx-2 rounded-circle zip-clr-btn' color='danger' outline onClick={clearSearch} >
                                                    <MDBIcon fas icon='times' />
                                                </MDBBtn>}
                                            {/* {(activeTab === 'CHEM' && chemicalName$) && <MDBBadge className='ms-2 d-inline' style={{
                                        zoom: '0.8',
                                    }}>{chemicalName$} </MDBBadge>} */}
                                        </h5>
                                    </div>
                                    : <div className="flex-grow-1 text-lg">
                                        {activeTab} Emissions from Large Facilities
                                    </div>
                                }
                                <div >

                                    {/* Added the below condition for displaying 'Select Chemical' dropdown in place of Save Report of CHEM dashboard */}

                                    {activeTab === 'CHEM' ? <ChemicalDropdown /> :
                                        <MDBBtn
                                            disabled={isLoading}
                                            className='me-1 mt-2 bg-gradient' color='success' onClick={showPDFOptions}>
                                            <MDBIcon fas icon="file-pdf" className='' />
                                            <span className='ms-2'>Generate PDF</span>
                                        </MDBBtn>
                                    }
                                </div>
                            </div>
                            {/* } */}

                            {/* </MDBCardText> */}
                        </MDBCardBody>
                    </MDBCard>

                    {/* If it is CHEM dashboard, we are rendering the Gauge chart and for all other dashboards it will be maps in iframe */}
                    {activeTab === 'CHEM' ? <RenderGauge /> :
                        <div className='shadow-5 mb-3 bg-light card p-3' ref={mapRef} style={{
                            width: 'calc(100% - 0px)',
                            // height: '80vh',
                            borderRadius: '8px',
                            boxShadow: 'var(--mdb-card-box-shadow)',
                            backgroundColor: 'var(--mdb-card-bg)',
                        }}>
                            {activeTab === 'GHG' ? <GhgMapTxt /> : ""}
                            {activeTab === 'TRI' ? <TriMapTxt /> : ""}
                            {activeTab === 'CEJST' ? <CejstMapTxt /> : ""}
                            {activeTab === 'SVI' ? <SviMapTxt /> : ""}
                            {activeTab === 'EBM' ? <EbmMapTxt /> : ""}
                            {activeTab === 'SVM' ? <SvmMapTxt /> : ""}
                            {activeTab === 'HVM' ? <HvmMapTxt /> : ""}

                            {/* TODO: working only for GHG... Yet to implement tab wise data render */}
                            <div ref={mapRefNew}>
                                {Object.keys(mapData).length}
                                {(isZipSearching$ || Object.keys(mapData).length ===0) ? 
                                <PlaceholderLoading className="loader" shape='rect' width={'100%'} height={300} />
                                : <MapComponent mapData={mapData} />}
                            </div>
                            {userData.USER_TYPE === "RESIDENT" && activeTab !== 'CHEM' &&
                                <div className='flex-grow-1 flex-column mt-3'>
                                    <div className={(activeTab === 'TRI' || activeTab === 'GHG') ? 'w-100 p-0' : 'w-100 h-auto bg-info p-0'}>
                                        <MDBTable borderless className='m-0 loc-table'>
                                            <MDBTableBody className='text-light'>
                                                <tr>
                                                    <td className='p-2 w-25'>State : &nbsp; {state}</td>
                                                    <td className='p-2 w-25'>City : &nbsp; {city}</td>
                                                    <td className='p-2 w-25'>Country : &nbsp; {country}</td>
                                                    <td className='p-2 w-25'>Zip code : &nbsp; {zipCode$}</td>
                                                </tr>
                                            </MDBTableBody>
                                        </MDBTable>
                                        {/* } */}

                                    </div>
                                </div>
                            }

                            {/* {activeTab === 'GHG' ? <TimelineTxt /> : ""} */}
                        </div>}


                    {/* Location card */}
                    <div className='d-md-flex flex-row mb-3 c-width clearfix d-sm-block'>


                        <div className='flex-grow-1 flex-column'>
                            {(activeTab === 'TRI' || activeTab === 'GHG') &&
                                <MDBCard className='card w-100 p-3 border-one shadow-box'>
                                    <MDBCardBody className='p-0'>
                                        {isZipSearching$ ? <PlaceholderLoading className="loader" shape='rect' width={'100%'} height={300} /> :
                                            hasData ? <div>
                                                {activeTab === 'GHG' ? <PieTxt /> : ""} {activeTab === 'TRI' ? <TriPieTxt /> : ""}
                                                <HighchartsReact
                                                    id="pieChart"
                                                    ref={pieChartPageRef}
                                                    highcharts={Highcharts}
                                                    options={chartOptions}
                                                />  </div> :
                                                <div className='p-2' style={{ height: '150px' }}>

                                                    <h5 className=''>{
                                                        chartOptions.title && chartOptions.title.text
                                                    }</h5>

                                                    <h5 className='text-center' style={{ marginTop: '100px' }}>No Data Available</h5>
                                                </div>

                                        }

                                    </MDBCardBody>
                                </MDBCard>
                            }
                        </div>
                    </div>
                    {/* trends card */}
                    {(activeTab === 'TRI' || activeTab === 'GHG') && <MDBCard className='mb-3 trends-card' ref={trendRef}>
                        <MDBCardBody className='p-0'>
                            {isZipSearching$ ? <PlaceholderLoading className="loader" shape='rect' width={'100%'} height={300} /> :
                                hasData ? <div className='inner-box'>{activeTab === 'GHG' ? <LineTxt /> : ""} {activeTab === 'TRI' ? <TriLineTxt /> : ""} <HighchartsReact
                                    id="trendsChart"
                                    ref={trendChartRef}
                                    highcharts={Highcharts}
                                    options={trendChartOptions}
                                /></div> :
                                    <div className='p-2' style={{ height: '300px' }}>
                                        <h5 className=''>{
                                            trendChartOptions.title && trendChartOptions.title.text
                                        }</h5>
                                        <h5 className='text-center' style={{ marginTop: '150px' }}>No Data Available</h5>
                                    </div>
                            }
                        </MDBCardBody>
                    </MDBCard>}

                    {/* data grid card */}
                    {(appConfig.lables[activeTab.toLowerCase()].table) &&
                        <MDBCard className='mb-3 grid-card' ref={gridRef}>
                            <MDBCardBody className='p-0'>
                                <div className='p-0'>
                                    <div className='scrollbar scrollbar-default'>
                                        {isZipSearching$ ? <PlaceholderLoading className="loader" shape='rect' width={'100%'} height={500} /> :
                                            hasData ? <div className='inner-box'
                                                id="trendsGrid"
                                                ref={trendsGridRef}>{activeTab === 'GHG' ? <TableTxt /> : ""} {activeTab === 'TRI' ? <TriTableTxt /> : ""} <Grid
                                                    gridData={gridData}
                                                    activeTab={activeTab}
                                                    gridHeight={500} /> </div> :
                                                <div className='p-2' style={{ height: '300px' }}>
                                                    <h5 className='text-center' style={{ marginTop: '150px' }}>No Data Available</h5>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    }
                    {/* Added condition to not display StickyMenu for Chem Dashboard. */}
                    {activeTab !== 'CHEM' && <StickyMenu mapRef={mapRef} chartRef={trendRef} tableRef={gridRef} genPdfFn={showPDFOptions} />}

                    {/* save report modal */}
                    <MDBModal
                        backdrop={false}
                        show={saveRptModal}
                        setShow={setSaveRptModal}
                        tabIndex='-1'
                        className='shadow-2'
                    >
                        <MDBModalDialog centered>
                            <MDBModalContent>
                                <MDBModalHeader className='px-4 py-2 bg-primary text-light'>
                                    <MDBModalTitle className=''>
                                        <MDBIcon fas icon="save" className='pr-2' />
                                        <span className='px-2'>Save Report</span>
                                    </MDBModalTitle>
                                    <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <MDBInput label='Report Name' id='form1' type='text'
                                        value={reportName}
                                        onChange={onChangeRptName} />
                                </MDBModalBody>

                                <MDBModalFooter>
                                    <MDBBtn color='secondary' onClick={toggleShow} disabled={isRptSaving}>
                                        <MDBIcon fas icon="times" className='pr-2' />
                                        Close
                                    </MDBBtn>
                                    <MDBBtn onClick={onSaveRpt}>
                                        {isRptSaving ? <MDBIcon fas icon="refresh" className='pr-2 fa-spin' />
                                            : <MDBIcon fas icon="save" className='pr-2' />}
                                        Save changes</MDBBtn>
                                </MDBModalFooter>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>

                    {/* Export to pdf */}
                    <MDBModal
                        backdrop={false}
                        show={canShowExportPDF}
                        setShow={setCanShowExportPDF}
                        tabIndex='-1'
                        className='shadow-2'
                    >
                        <MDBModalDialog centered size='xl'>
                            <MDBModalContent>
                                <MDBModalHeader className='px-4 py-2 bg-primary text-light'>
                                    <MDBModalTitle className=''>
                                        <MDBIcon fas icon="file-pdf" className='pr-2' />
                                        <span className='px-2'>{`${getCurrentYear()}-${activeTab}-${zipCode$}-${userData.FULLNAME}_Report.pdf`}</span>
                                    </MDBModalTitle>
                                    <div style={{marginTop: -10}}>
                                        {/*  */}
                                        <PDFDownloadLink className='ripple ripple-surface ripple-surface-light btn btn-danger me-1 mt-2 bg-gradient'
                                            document={<GeneratePDF
                                                ref={docInstance}
                                                docTitle={`${getCurrentYear()}-${activeTab}-${zipCode$}-${userData.FULLNAME}_Report.pdf`}
                                                activTab={activeTab}
                                                zipCode={zipCode$}
                                                userData={userData}
                                                map={mapImg}
                                                pieChart={piechartImg}
                                                trendsChart={trendsChartImg}
                                                gridData={gridData}
                                            />}
                                            fileName={`${getCurrentYear()}-${activeTab}-${zipCode$}-${userData.FULLNAME}_Report.pdf`} // Set your custom file name here
                                            >
                                            {({ loading }) => (loading ? '...' : <MDBIcon fas icon="download" />)}
                                        </PDFDownloadLink>
                                        <MDBBtn className='btn-close ms-3'style={{
                                            display: 'inline-block',
                                            top: '-9px',
                                        }} color='none' onClick={() => { 
                                            setMapImg('')
                                            setPiechartImg('')
                                            setTrendsChartImg('')
                                            setCanShowExportPDF(false)
                                         }}>
                                            <MDBIcon fas icon="times" />
                                        </MDBBtn>
                                    </div>
                                </MDBModalHeader>
                                <MDBModalBody className='pdf-form p-0 m-0' style={{
                                    height: '80vh'
                                }} >
                                    <PDFViewer 
                                        showToolbar={false}
                                        style={{
                                            width: '100%',
                                            height: '99%',
                                        }} >
                                        {/* <GeneratePDF
                                            ref={docInstance}
                                            docTitle={`${getCurrentYear()}-${activeTab}-${zipCode$}-${userData.FULLNAME}_Report.pdf`}
                                            activTab={activeTab}
                                            zipCode={zipCode$}
                                            userData={userData}
                                            map={mapImg}
                                            pieChart={piechartImg}
                                            trendsChart={trendsChartImg}
                                            gridData={gridData}
                                        /> */}
                                    </PDFViewer>
                                </MDBModalBody>

                                {/* <MDBModalFooter className='p-1'>
                                    <MDBBtn color='secondary' onClick={() => { setCanShowExportPDF(false) }} disabled={isRptSaving}>
                                        <MDBIcon fas icon="times" className='pr-2' />
                                        <span className='ms-2'>Close</span>
                                    </MDBBtn>
                                </MDBModalFooter> */}
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>

                    <ToastContainer />
                    <CopyrightsFooter />
                </>
            )}
        </>
    )
}

// export default withAITracking(reactPlugin, Dashboard);
export default Dashboard;
