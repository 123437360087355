import appConfig from "../configs/App.config";

const DataNormalizer = {
    normalizeTrendsData: (rawData, tab) => {
        const mapdata =  {};
        return rawData.then((data) => {
            // check the error
            if (data.error) {
                return {
                    error: data.error,
                    headers: [],
                    data: [],
                    features: [],
                    years: []
                };
            }
            let result = {};
            let years = new Set();
            data.features.map((feature) => {
                let FACILITY_NAME = feature.properties.FACILITY_NAME;
                let QUANTITY = feature.properties.GHG_QUANTITY;
                // let year = new Date(feature.properties.REPORTING_YEAR).getFullYear();
                let year = feature.properties.REPORTING_YEAR;
                years.add(year);
                result[FACILITY_NAME] = result[FACILITY_NAME] || [];
                result[FACILITY_NAME]["_"+year] = result[FACILITY_NAME]["_"+year] || [];
                result[FACILITY_NAME]["_"+year]= {
                    name: FACILITY_NAME,
                    value: QUANTITY,
                    year: year
                };

                // adding map data
                if (!mapdata[`_${year}`]) {
                    mapdata[`_${year}`] = {};
                }
                if (!mapdata[`_${year}`]['points']) {
                    mapdata[`_${year}`]['points'] = {};
                }
                if (!mapdata[`_${year}`]['points'][FACILITY_NAME]) {
                    mapdata[`_${year}`]['points'][FACILITY_NAME] = {
                        coordinates: feature.geometry.coordinates.reverse(),
                        ...feature.properties
                    };
                }
            })

            years = [...years].sort().reverse();
            years = years.slice(0, 10).reverse();
            mapdata['years'] = years;
            let headers = [appConfig.trendsGridFirstCol.replace("{tab}", tab.toUpperCase()), ...years]
            let finalResult = [];
            let features = [];
            // while (Object.keys(result).length > 10) {
            //     let y = Object.keys(result)[0];
            //     delete result[y];
            // }

            Object.keys(result).map((facility, fi) => {
                let row = {}
                headers.forEach((head, ind) => {
                    if (ind === 0) {
                        row['key'] = facility.replaceAll(' ', '_').toLowerCase()+'_'+ind;
                        row['feature'] = facility;
                        row['g_field'] = facility.replaceAll(' ', '_').toLowerCase();
                        row['id'] = facility.replaceAll(' ', '_').toLowerCase();
                        // row['state'] = "closed";
                    }else{
                        row[`_${head}`] = result[facility]["_"+head]? result[facility]["_"+head]['value'] : 0;
                    }
                });
                finalResult.push(row);
                features.push(facility);
            });

            return {
                years: years,
                headers: headers,
                data: finalResult,
                features: features,
                mapPoints: mapdata,
            };
        }).catch((err) => {
            return {
                headers: [],
                data: [],
                features: [],
                years: [],
                mapPoints:{}
            };
        });
    },

    normalizeTRITrendsData: (rawData, tab) => {
        return rawData.then((data) => {
            // check the error
            if (data.error) {
                return {
                    error: data.error,
                    headers: [],
                    data: [],
                    features: [],
                    years: []
                };
            }
            let result = {};
            let years = new Set();
            data.features.map((feature) => {
                let FACILITY_NAME = feature.properties.FACILITY_NAME;
                let QUANTITY = parseFloat(feature.properties.TotalVal);
                let CHEMICAL = feature.properties.chemical_name;
                let year = feature.properties.REPORTING_YEAR;
                years.add(year);
                result[FACILITY_NAME] = result[FACILITY_NAME] || {};
                result[FACILITY_NAME][CHEMICAL] = result[FACILITY_NAME][CHEMICAL] || {};
                result[FACILITY_NAME][CHEMICAL][year]={
                    name: CHEMICAL,
                    value: parseFloat(QUANTITY.toFixed(2)),
                    year: year,
                    facility: FACILITY_NAME
                };
            })
            years = [...years].sort().reverse();
            years = years.slice(0, 10).reverse();
            let features = new Set(Object.keys(result));

            // final tree grid data structure fevilitities -> chemicals -> years
            let finalResult = [];
            let headers = [appConfig.trendsGridFirstCol.replace("{tab}", tab.toUpperCase()), ...years]
            Object.keys(result).map((facility, fi) => {
                let row = {}
                headers.forEach((head, ind) => {
                    if (ind === 0) {
                        row['key'] = facility.replaceAll(' ', '_').toLowerCase()+'_'+ind;
                        row['feature'] = facility;
                        row['g_field'] = facility.replaceAll(' ', '_').toLowerCase();
                        row['id'] = facility.replaceAll(' ', '_').toLowerCase();
                        // row['state'] = "closed";
                    }else{
                        row[`_${head}`] = 0;
                    }
                });
                let children = [];
                Object.keys(result[facility]).map((chemical, ci) => {
                    let childrenRow = {};
                    headers.forEach((head, ind) => {
                        if (ind === 0) {
                            childrenRow['key'] = `${facility.replaceAll(' ', '_').toLowerCase()}_${fi}_${ind}_${ci}`;
                            childrenRow['feature'] = chemical;
                            childrenRow['g_field'] = facility.replaceAll(' ', '_').toLowerCase();
                            childrenRow['id'] = `${facility.replaceAll(' ', '_').toLowerCase()}_${chemical.replaceAll(' ', '_').toLowerCase()}`;
                        }else{
                            childrenRow[`_${head}`] = 0;
                        }
                    });
                    Object.keys(result[facility][chemical]).map((year) => {
                        let val = parseFloat((result[facility][chemical][year].value).toFixed(2));
                        childrenRow[`_${year}`] = Number(val.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        row[`_${year}`] += val;
                    });
                    children.push(childrenRow);
                    // finalResult.push(childrenRow);
                });
                row.children = children;
                finalResult.push(row);
            });

            return {
                years: years,
                headers: headers,
                data: finalResult,
                features: [...features],
                graphValues: []
            };
        }).catch((err) => {
            return {
                headers: [],
                data: [],
                features: [],
                years: []
            };
        });
    },

    normalizeOTTrendsData: (rawData, tab) => {
        return rawData.then((data) => {
            // check the error
            if (data.error) {
                return {
                    error: data.error,
                    headers: [],
                    data: [],
                    features: [],
                    years: []
                };
            }
            let result = [];
            let features = new Set();
            let headers = [appConfig.trendsGridFirstCol.replace("{tab}", tab.toUpperCase()), 'County', tab.toUpperCase()]
            data.features.map((feature) => {
                let FACILITY_NAME = feature.properties.NAME;
                let QUANTITY = parseFloat(feature.properties[tab.toUpperCase()]);
                let CHEMICAL = feature.properties.COUNTY;
                let row = {}
                features.add(FACILITY_NAME);
                headers.forEach((head, ind) => {
                    switch (ind) {
                        case 0:
                            row['key'] = FACILITY_NAME.replaceAll(' ', '_').toLowerCase()+'_'+ind;
                            row['feature'] = FACILITY_NAME;
                            row['g_field'] = FACILITY_NAME.replaceAll(' ', '_').toLowerCase();
                            row['id'] = FACILITY_NAME.replaceAll(' ', '_').toLowerCase();
                            break;
                        case 1:
                            row['County'] = CHEMICAL;
                            break;
                        case 2:
                            row[tab.toUpperCase()] = parseFloat(QUANTITY.toFixed(2));
                            break;
                        default:
                            break;
                    }
                });

                result.push(row);
            })

            return {
                headers: headers,
                data: result,
                features: [...features],
                graphValues: []
            };
        }).catch((err) => {
            return {
                headers: [],
                data: [],
                features: [],
                years: []
            };
        });
    },

    normalizeChemNamesData: (rawData) => {
        return rawData.then((data) => {
            if (data.error) {
                return {
                    error: data.error,
                    headers: [],
                    data: [],
                    features: [],
                    years: []
                };
            }
            let result = [];
            data.features.map((feature) => {
                let row = {}
                row['chemicalName'] = feature.properties.chemical_name;
                row['id'] = feature.properties.id;
                result.push(row);
            })

            return {
                data: result
            };
        }).catch((err) => {
            return {
                data: []
            };
        });
    },

    normalizeChemDetailsResponse: (rawData) => {
        return rawData.then((data) => {
            if (data.error) {
                return {
                    error: data.error,
                    headers: [],
                    data: [],
                    features: [],
                    years: []
                };
            }

            let result = [];
            data.features.map((feature) => {
                let row = {}
                row['carcinogenicity'] = feature.properties.carcinogenicity;
                row['developmental_toxicity'] = feature.properties.developmental_toxicity;
                row['neurotoxicity_single_exposure'] = feature.properties.neurotoxicity_single_exposure;
                row['eye_irritation'] = feature.properties.eye_irritation;
                row['Skin_Irritation'] = feature.properties.Skin_Irritation;
                row['reproductive_toxicity'] = feature.properties.reproductive_toxicity;
                row['Respiratory_Sensitization'] = feature.properties.Respiratory_Sensitization;
                result.push(row);
            })
            return {
                data: result
            };
        }).catch((err) => {
            return {
                data: []
            };
        });
    }
}

export default DataNormalizer;
