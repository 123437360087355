import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from '@react-pdf/renderer';
import PDFHeader from './PDFHeader';
// Create Document Component
const GeneratePDF = (props) => {
    const styles = StyleSheet.create({
        tr: {
            border: '1px solid #2b2c2d',
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
        },
        th: {
            border: '1px solid #2b2c2d',
            padding: 5,
            fontSize: 12,
            fontWeight: 'bold',
            backgroundColor: '#d4dcdc',
        },
        td: {
            border: '1px solid #2b2c2d',
            padding: '4px 5px',
            fontSize: 8,
            minHeight: 20,
        },
    });
    return (
        <Document 
        creator='EJDCP' 
        author='EJDCP'
        subject='EJDCP PDF Report'
        keywords={`EJDCP, ${props.activeTab}, ${props.userData.FULLNAME}, ${props.zipCode}, ${props.activeTab}`}
        producer='EJDCP'
        title={props.docTitle}>
            <Page
                size="A4"
                orientation='landscape'
                style={{ flexDirection: 'column' }}>
                <PDFHeader {...props} ComponentFlag={'Map View'} />
                <div>
                    <Image src={props.map} style={{
                        marginTop: 20,
                        marginLeft: 20,
                        width: '95%',
                    }}  />
                </div>
            </Page>
            <Page size="A4" orientation='landscape' style={{ flexDirection: 'column' }}>
                <PDFHeader {...props} ComponentFlag={'Facilities percentage share'} />
                    <Image src={props.pieChart} style={{
                        marginTop: 20,
                        marginLeft: 20,
                        width: '170%',
                    }}  />
            </Page>
            <Page size="A4" orientation='landscape' style={{ flexDirection: 'column' }}>
                <PDFHeader {...props} ComponentFlag={'Total Emitions Trends (Graph)'} />
                <Image src={props.trendsChart} style={{
                        marginTop: 50,
                        marginLeft: 20,
                        width: '95%',
                    }}  />
            </Page>
            <Page size="A4" orientation='landscape' style={{ flexDirection: 'column' }}>
                <PDFHeader {...props} ComponentFlag={'Total Emitions Trends(Table)'} />
                {/* <View > */}
                    <table style={{ 
                        width: '98%', 
                        border: '1px solid #2b2c2d', 
                        // borderCollapse: 'collapse',
                        marginLeft: 10, 
                        marginTop: 10,
                        }}>
                        <tr style={styles.tr}>
                            {
                                props.gridData.headers.map((lbl, i) => (<th style={{
                                    ...styles.th,
                                    width: (i === 0) ? 280 : 54,
                                }}><Text>
                                    {(i === 0) ? lbl.replace('{tab}', props.activeTab) : lbl}
                                    </Text></th>))
                            }
                        </tr>
                        {props.gridData.data.map((item, index) => (
                            <><tr style={styles.tr}>
                                <td style={{
                                    ...styles.td,
                                    width: 280,
                                }}><Text>{item.feature}</Text></td>
                                {props.gridData.years.map((iy, index) => (<td style={{
                                    ...styles.td,
                                    width: 54,
                                }}><Text>{item[`_${iy}`]}</Text></td>))}
                            </tr>
                            {item.children && item.children.map((child, cIndex) => (
                                <tr style={styles.tr}>
                                    <td style={{
                                        ...styles.td,
                                        width: 280,
                                    }}><Text> -- {child.feature}</Text></td>
                                    {props.gridData.years.map((iy, index) => (<td style={{
                                        ...styles.td,
                                        width: 54,
                                    }}><Text>{child[`_${iy}`]}</Text></td>))}
                                </tr>
                            ))}</>
                        ))}
                    </table>
                {/* </View> */}
            </Page>
        </Document>
    );
}

export default GeneratePDF;