import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'

function PDFHeader(props) {
    
  return (
    <View>
                <div style={{
                    width: '100%',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'start',
                    position: 'fixed',
                    flexDirection: 'row',
                    top: 0,
                    left: 0,
                    borderBottom: '1px solid #2b2c2d',
                }}>
                    <div style={{
                        width:'30%',
                        backgroundColor: '#2b2c2d',
                        padding: 5,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                    }}>
                        <Image src="/images/ejdap-banner-logo.png" style={{ width: 120, height: 85, marginLeft:-20 }} />
                    </div>
                    <div style={{
                        width:'50px',
                        height: '60px',
                        backgroundColor: 'white',
                        position: 'absolute',
                        top: -19,
                        left: '27.96%',
                        transform: 'rotate(-25deg)',
                        borderLeft: '1px solid #2b2c2d',
                    }}></div>
                    <div style={{
                        width:'70%',
                        color: '#2b2c2d',
                        padding: 5,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <div>
                        <Text style={{ color: '#2b2c2d' }}>EJDAP - {props.ComponentFlag}</Text>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: 5,
                        }}>
                            <view style={{display:'flex', flexDirection:'row'}}>
                                <Text style={{ color: '#2b2c2d', fontSize:10, fontWeight:'heavy' }}>CBO :</Text>
                                <Text style={{ color: '#2b2c2d', fontSize:10 }}> {props.userData.FULLNAME}</Text>
                            </view>
                            {/* <view style={{display:'flex', flexDirection:'row'}}>
                                <Text style={{ color: '#2b2c2d', fontSize:10, fontWeight:'bold' }}>ZIP :</Text>
                                <Text style={{ color: '#2b2c2d', fontSize:10 }}> {props.zipCode}</Text>
                            </view> */}
                            <view style={{display:'flex', flexDirection:'row'}}>
                                <Text style={{ color: '#2b2c2d', fontSize:10, fontWeight:'bold' }}>METRIC :</Text>
                                <Text style={{ color: '#2b2c2d', fontSize:10 }}> {props.activTab}</Text>
                            </view>
                        </div>
                    </div>
                </div>
            </View>
  )
}

export default PDFHeader